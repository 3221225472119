/* Insufficient contrast against white - may be reasonable when used as background colour against black */
/* Accessible colours with good contrast (at least against white) */
.mcd-search-widget {
  font-family: Arial, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.6em;
  padding: 0 5px 5px;
  max-width: 100%;
  margin-bottom: 0 !important;
  vertical-align: top;
}
.mcd-search-widget div.container label,
.mcd-search-widget div.container input,
.mcd-search-widget div.container select a {
  display: block;
}
.mcd-search-widget div.container label {
  padding: 10px 0 3px 0;
  font-weight: bold;
}
.mcd-search-widget div.container > div:not(.provided-by) {
  margin-right: 5px;
  vertical-align: top;
  min-width: 150px;
  max-width: 300px;
  width: 100%;
}
.mcd-search-widget div.container > div:not(.provided-by):nth-child(4) {
  text-align: center;
}
.mcd-search-widget div.container label {
  display: block;
}
.mcd-search-widget div.container .twitter-typeahead {
  width: 100%;
}
.mcd-search-widget div.container .twitter-typeahead input {
  width: 100%;
}
.mcd-search-widget div.container .twitter-typeahead .tt-dropdown-menu {
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #dddddd;
  max-height: 400px;
  overflow-y: auto;
  padding: 15px 0;
  width: 100%;
  box-sizing: border-box;
}
.mcd-search-widget div.container .twitter-typeahead .tt-suggestion {
  cursor: pointer;
  padding: 0 15px;
  box-sizing: border-box;
}
.mcd-search-widget div.container .twitter-typeahead .tt-suggestion:hover {
  background: #eeeeee;
  background: rgba(0, 0, 0, 0.1);
}
.mcd-search-widget div.container .twitter-typeahead .tt-suggestion p {
  color: #444444;
  margin: 0;
  padding: 5px 2px;
  line-height: 1.57143em;
}
.mcd-search-widget div.container .twitter-typeahead .tt-suggestion p strong {
  font-weight: 700;
  box-sizing: border-box;
}
.mcd-search-widget div.container .twitter-typeahead .tt-suggestion.tt-cursor {
  color: #fff;
  background: #f15b22;
}
.mcd-search-widget div.container .twitter-typeahead .tt-suggestion.tt-cursor p {
  color: #fff;
}
.mcd-search-widget div.container .twitter-typeahead .tt-suggestion.tt-cursor:hover {
  background: #f15b22;
}
.mcd-search-widget div.container .twitter-typeahead .tt-suggestion.tt-cursor:hover p {
  color: #fff;
}
.mcd-search-widget div.container .twitter-typeahead .tt-hint {
  color: #797676 !important;
}
.mcd-search-widget div.container input[type='text'],
.mcd-search-widget div.container select {
  height: 34px !important;
  padding: 6px 12px !important;
  font-size: 14px;
  color: #555;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  box-sizing: border-box;
  width: 100%;
}
.mcd-search-widget div.container .search-button {
  display: block;
  color: #fff;
  background-color: #f15b22;
  padding: 0.5em 1em;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  font-weight: 600;
  text-decoration: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  margin-top: 20px;
  text-transform: uppercase;
  clear: right;
}
.mcd-search-widget div.container .search-button:hover {
  background-color: #f05b01;
}
.mcd-search-widget div.container .provided-by {
  font-size: 10px;
  color: #797676;
  vertical-align: top;
  padding-top: 20px;
  display: block;
  margin-bottom: 0;
}
.mcd-search-widget div.container .provided-by > div {
  display: inline-block;
}
.mcd-search-widget div.container .provided-by div:first-child {
  vertical-align: top;
  padding-right: 5px;
  min-width: 150px;
}
.mcd-search-widget div.container .provided-by div:last-child {
  background-image: url('/_static/images/mcd.png?v=2025.04.01.8');
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  width: 120px;
}
@media only screen and (min-width: 1080px) {
  .mcd-search-widget div.container > div:not(.provided-by) {
    display: inline-block;
  }
  .mcd-search-widget div.container > div:not(.provided-by):nth-child(3),
  .mcd-search-widget div.container > div:not(.provided-by):nth-child(4) {
    width: auto;
    min-width: 100px;
  }
  .mcd-search-widget div.container > div:not(.provided-by):nth-child(4) {
    padding-top: 30px;
    margin-top: -15px;
  }
}
